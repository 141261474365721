import React from "react";
import "../../../variables.css";
import "../ButtonTwo/buttonTwo.css"

class ButtonTwo extends React.Component{
    render() {
        return(
            <button className="btn_two">Get Started</button>
        )
    }
}

export default ButtonTwo;