import React from "react";
import "../../../variables.css";
import "../ButtonThree/buttonThree.css";

class ButtonThree extends React.Component{
    render() {
        return(
            <button className="footer_btn">Get Started</button>
        )
    }
}

export default ButtonThree;