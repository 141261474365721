import React from "react";
import "../../../variables.css"
import "../ButtonOne/buttonOne.css"

class ButtonOne extends React.Component{
    render() {
        return(
            <button className="btn_one">Get Started</button>
        )
    }
}

export default ButtonOne;